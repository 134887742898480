//
//
//
//
//
//
//
//
//
//

import Note from "./Note.vue";

import gamePlayCopy from "../data/copy";

export default {
  components: {
    Note
  },
  data: () => ({
    COPY: {}
  }),

  props: {
    copy: Object
  },

  methods: {
    next() {
      this.$emit("next");
      this.$router.push('/');
    }
  },

  created() {
    this.COPY = gamePlayCopy.sessionComplete;
  }
};
