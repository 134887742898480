//
//
//
//
//
//

/***
 * Game.vue files are the entry point for all games, where we set the different
 * game step components, set the game ID, and handle most settings.
 */
import Loading from "../../Common/Loading";
import Introduction from "../../Common/Introduction";
import Instructions from '../../Common/Instructions';
import Play from "./components/Play";
import Assets from "./data/assets";

import GlobalMixin from "../../../../shared/GlobalMixins.js";
import GamePlayMixin from "../../../../shared/GamePlayMixin.js";
import gamePlayCopy from "./data/copy";

export default {
  name: "TheDecoder",
  mixins: [GlobalMixin, GamePlayMixin],
  components: {
    Loading,
    Introduction,
    Instructions,
    Play
  },
  async created() {
    await this.initializeGame({
      gameId: 7,
      gameName: 'TheDecoder',
      assets: Assets,
      instructions: gamePlayCopy.instructions,
      backgroundImage: Assets.images.background
    });

    document.title = 'Incremental Health: The Decoder';
  }
};
