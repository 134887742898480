//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from "src/shared/GlobalMixins.js";
import GamePlayMixin from "src/shared/GamePlayMixin.js";
import InitializeGameMixin from "src/shared/InitializeGameMixin";

import Animation from "src/shared/Animation.js";
import gamePlayCopy from "../data/copy.js";

import FindCode from "./FindCode.vue";
import CrackCode from "./CrackCode.vue";

import EndSession from "./EndSession.vue";

const Scoring = Object.freeze({
  CRACKED: 200,
  UNCRACKED: -100,
  LEVEL_UP: 150,
  DECODE: 10,

  CORRECT_DIGIT: 10,
  INCORRECT_DIGIT: -50
});

export default {
  name: "Play",
  mixins: [GlobalMixin, GamePlayMixin, InitializeGameMixin, Animation],
  components: {
    FindCode,
    CrackCode,
    EndSession

  },

  data() {
    return {
      phase: "loading", // "findCode" | "crackCode" | "roundComplete" | "levelUp" | "endSession"
      code: "",
      crackedCount: 0,
      uncrackedCount: 0,
      correctThisRound: 0,
      playStart: null,
      endRoundText: '',
      trialStart: null,
      transitionName: "drop-in",
      totalAnswersPerTrial: 0,
      gamePlayCopy: gamePlayCopy
    };
  },

  methods: {
    async initializeGamePlay() {
      await this.getSettings();
      this.startGamePlayMode();
    },
    async getSettings() {
      let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });

        this.totalAnswersPerTrial = gameData.game_settings.stim_per_trial;
        console.log('total answers per trial', this.totalAnswersPerTrial)
        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);
        this.setLevel(this.gameLevelData.start_level);
        this.setRounds(5);
    },
    generateNewCode() {
      const gameSettings = this.gameLevelData.game_settings;
      const sequenceLength = this.totalAnswersPerTrial;

      let letterCount = Math.min(
          this.getRandomIntegerInRange(
              gameSettings.letter_stim_low,
              gameSettings.letter_stim_high
          ),
          sequenceLength - 1
      );

      let numberCount = sequenceLength - letterCount;
      console.log('numberCount', letterCount, numberCount, sequenceLength)
      // Ensure at least one letter and one number if sequenceLength >= 2
      if (sequenceLength >= 2) {
        if (letterCount === 0) {
          letterCount = 1;
          numberCount--;
        } else if (numberCount === 0) {
          numberCount = 1;
          letterCount--;
        }
      }

      const letterBag = this.shuffleArray("ABCDEFGHIJKLMNPQRSTUVXYZ".split(""));
      const numberBag = this.shuffleArray("123456789".split(""));

      const numbers = numberBag.slice(0, numberCount).join('');
      const letters = letterBag.slice(0, letterCount).join('');

      return [...numbers, ...letters]
          .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0))
          .join("");
    },
    startGamePlayMode() {
      console.log('startGamePlayMode')
      this.playStart = new Date().getTime();
      this.code = this.generateNewCode();
      console.log('code', this.code)
      this.phase = "findCode";
      this.trialStart = new Date().getTime();
      this.setMusic(this.assets.audio.gamePlay, true);
    },
    completeRound() {
      if (this.uncrackedCount === 0) {
        this.phase = "levelUp";
      } else {
        this.nextRound();
      }
    },
    async nextRound() {
      // determine level for next round
      if (this.uncrackedCount === 0) {
        // all characters are correct
        this.setLevel(this.level + this.levelChangeRound);
        this.increaseTickets(3);
        this.setTicketEffect();

      } else if (this.crackedCount === 0) {
        // all characters are incorrect
        this.setLevel(Math.max(this.level - this.levelChangeRound, 1));
      }

      // go to next level
      await this.updateGameLevel(this.tickets);
      await this.getSettings();

      if (this.currentRound < this.rounds) {
        // prep for next round
        this.setCurrentRound(this.currentRound + 1);
        this.crackedCount = 0;
        this.uncrackedCount = 0;

        this.startGamePlayMode();
      } else {
        await this.saveTrialResults();
        this.phase = "endSession";
      }
    },
    /**
     * this is the original structure we need to move this shit into
     */
    async determineNextGamePhase(allCorrect) {
      if (allCorrect) {
        this.crackedCount += 1;
      } else {
        this.uncrackedCount += 1;
      }

      this.correctThisRound = this.crackedCount;

      // save the trial results
      await this.setGameLevelAndSaveTrialResults();

      if (!this.gamePlayComplete) {
        console.log("gameplay not complete");
        this.remainingTrials ? await this.goToNextTrial(allCorrect) : await this.getRoundScoreAndGoToNext();

      } else {
        console.log('gameplay is complete');
        await this.handleGamePlayComplete();
      }
    },
    /**
     * @method getRoundScoreAndGoToNext
     * @description Get the round score and go to the next round
     * @returns {void}
     */
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    /**
     * @method handleGamePlayComplete
     * @description The game play is complete. Show the end of game modal and update the play level
     * @returns {void}
     */
    async handleGamePlayComplete() {
      console.log('game play is complete');
      this.stopMusic();
      await this.endRoundScore();

      this.playDisabled = true;
      this.increaseTickets(7);
      this.setTicketEffect();
      this.setEffect(this.assets.audio.gameEnd);
      await this.updatePlayLevel({
        gameId: this.gameId, play_id: this.play_id, level: Math.max(1, this.level), tickets: this.tickets, stopTime: new Date().getTime()
      });


      this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
    },
    async goToNextTrial(allCorrect = true) {
      this.endRoundText = '';
      this.playDisabled = true;
      this.clearPreviousLocations();
      this.modalMessageReset();

      allCorrect ? this.increaseScore(Scoring.CRACKED) : this.decreaseScore(Scoring.UNCRACKED);

      await this.sleep(2400); // so we see stuff before it goes away?
      this.playDisabled = false;

      this.setTimer('false');
      this.startNewRound();
      if (this.currentTrial === 1) {
        this.resetTrialsCorrect();
        this.clearTrialStatus();
        this.correctThisRound = 0; // reset this for new rounds
        await this.getSettings();
      }

      this.startGamePlayMode();
    },
    async endRoundScore() {
      if (this.allAnswersCorrect) {
        console.log("got all of the answers correct, go up a level");
        this.goUpLevel();
        this.increaseScore(Scoring.LEVEL_UP);
        this.increaseTickets(4);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log("some answers are correct, stay at same level");
      }

      if (this.noAnswersCorrect) {
        console.log("all of the answers are wrong, go down a level");

        if (this.level > 1) this.goDownLevel();
      }

      // change level and tickets in database, create round? record trials?
      // record this data, but also round, trial
      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });

      this.incorrectAnswers = false;
    },
    async goToNextRound() {

      this.playDisabled = true;
      let endRoundText = "";

      if (this.allAnswersCorrect) {
        endRoundText = gamePlayCopy.rounds.all;
      }
      if (this.someAnswersCorrect) {
        endRoundText = gamePlayCopy.rounds.some;
      }
      if (this.noAnswersCorrect) {
        endRoundText = gamePlayCopy.rounds.none;
      }

      // prep for next round
      this.crackedCount = 0;
      this.uncrackedCount = 0;
      this.endRoundText = endRoundText;

      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: this.trialsCorrect}),
        actionButton: {
          action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
          text: gamePlayCopy.rounds.playNext
        }
      });
    },
    showEndOfRoundModal() {
      this.setModalMessage({
        title: this.formatText(gamePlayCopy.rounds.end, {round: this.currentRound}, false),
        body: this.formatText(this.endRoundText, {correct: this.correctThisRound}),
        actionButton: {action: this.goToNextTrial, text: gamePlayCopy.rounds.playNext}
      });

      return true;
    },
    /** end structure*/
    endSession() {
      this.leaveGame();
    },
    decodeScore() {
      this.increaseScore(Scoring.DECODE);
    },
  },
  computed: {
    allAnswersCorrect() {
      return this.trialsCorrect === this.trials;
    },
    someAnswersCorrect() {
      return this.trialsCorrect > 0 && this.trialsCorrect < this.trials;
    },
    noAnswersCorrect() {
      return this.trialsCorrect === 0;
    },
  },
  async mounted() {
    await this.initializeGamePlay();
  }
};
