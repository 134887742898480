//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Note from "./Note.vue";
import Keyboard from "./Keyboard.vue";

import GlobalMixin from "src/shared/GlobalMixins.js";
import GamePlayMixin from "src/shared/GamePlayMixin.js";

import gamePlayCopy from "../data/copy.js";

export default {
  components: {
    Note,
    Keyboard
  },
  mixins: [GlobalMixin, GamePlayMixin],

  props: {
    code: String,
    copy: Object
  },
  data() {
    return {
      inputCode: "",

      correctCharacters: [],
      incorrectCharacters: [],

      state: "cracking" // 'cracking' | 'correct' | 'incorrect'
    };
  },

  computed: {
    inputNumbers() {
      return this.inputCode.match(/^[1-9]*/g)[0];
    },
    inputLetters() {
      return this.inputCode.match(/[A-Z]*$/g)[0];
    }
  },

  methods: {
    async submitCode() {

      const incorrectCharacterIndices = [...this.code].map((codeCharacter, index) => codeCharacter !== this.inputCode.substr(index, 1) ? index : -1).filter(index => index !== -1);
      if (
          this.code.length === this.inputCode.length &&
          incorrectCharacterIndices.length === 0
      ) {

        this.state = "correct";
        this.updateTrialsCorrect(this.trialsCorrect+1);
        this.trialWasCorrect = true;
        this.setTrialStatus('correct');
      } else {
        this.state = "incorrect";
        this.trialWasCorrect = false;
        this.setTrialStatus('incorrect');

      }

      for (const [index, codeCharacter] of [...this.code].entries()) {
        if (codeCharacter === this.inputCode.substr(index, 1)) {
          this.correctCharacters.push(index);
        } else {
          this.incorrectCharacters.push(index);
        }
        await this.sleep(700);
      }
    },

    next() {
      this.$emit("next", this.incorrectCharacters.length === 0);
    }
  },

  async mounted() {
    // show instructions if needed
    this.setTimerStopAfter(600);
    await this.sleep(500);
    await this.startTimer(200);
    if (this.level === 1 && this.currentTrial === 1) {
      this.setModalMessage({
        title: gamePlayCopy.instructions.crackCodeTitle,
        body: gamePlayCopy.instructions.crackCodeBody,
        actionButton: {
          action: () => (this.resetModalMessage()),
          text: gamePlayCopy.instructions.buttonText
        }
      });
    }
  }
};
