export default {
    instructions: [
        {
            title: "Uncover Ancient Secrets",
            'body': 'You\'ve come at a critical time.<br> \n' +
                'Only you can read these newly discovered scrolls.',
            'buttonText': 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Translate the Symbols',
            'body': 'Click each pulsing symbol to translate it.<br>Remember the translations!',
            'buttonText': 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
          title: 'Re-Order the Translations',
            body: 'Rearrange the numbers and letters, then enter them into your electronic journal.<br>' +
                'NUMBERS from smallest to biggest, THEN<br>' +
                'LETTERS in alphabetical order.',
            buttonText: 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ],

    crackCode: {
        title: "Now Crack the Code!",
        description: "Click the translated numbers from smallest to biggest, then the letters in alphabet order. We're counting on you!",

        failTitle: "Keep working on those codes!",
        failDescription: "That's not quite right, give it another try!",

        succeedTitle: "By Jove, you've got it!",
        succeedDescription: "Code cracked!",

        submitButton: "Submit the Code",
        backspaceButton: "⌫",
        clearButton: "Clear"
    },

    roundComplete: {
        titleRender: (roundNumber) => `Round ${roundNumber} is complete!`,
        succeedRender: (cracked) => `You cracked ${cracked} codes, good work!`,
        fail: "Keep practicing those decoding skills!"
    },
    rounds: {
        end: "Round {{round}} is complete!",
        playNext: "Play next round!",
        some: "You cracked {{correct}} codes, good work!",
        all: "Great job! You cracked {{correct}} codes correctly in this round.",
        none: "Keep practicing those decoding skills!"
    },

    levelUp: {
        title: "Amazing Job!!! UP RANK",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    gameComplete: {
        playTitle: "Session Completed!",
        playInstructions: "Great work, keep working on those decoding skills!",
        playButton: 'Return to games'
    },
    mainInstructions: [
        {
            'title': 'Uncover Ancient Secrets',
            'body': 'You\'ve come at a critical time.<br>\n' +
                'Only you can read these newly discovered scrolls.',
            'buttonText': 'Onward!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Translate the Symbols',
            'body': 'Click each pulsing symbol to translate it.<br>\n' +
                'Remember the translations!',
            'buttonText': 'Onward!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Reorder the Translations',
            'body': 'Rearrange the numbers and letters, then enter them into your electronic journal.<br>' +
                'NUMBERS from smallest to biggest, THEN<br>' +
                'LETTERS in alphabetical order.',
            'buttonText': 'Onward!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
};
