//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CodeCharacter from "./CodeCharacter.vue";

import GlobalMixin from "src/shared/GlobalMixins.js";
import GamePlayMixin from "src/shared/GamePlayMixin.js";
import Animation from "src/shared/Animation.js";

export default {
  components: {
    CodeCharacter
  },
  mixins: [Animation, GlobalMixin, GamePlayMixin],

  props: {
    code: String
  },
  data() {
    return {
      cursor: {show: false, left: 0, top: 0, lockPosition: false},
      characterLocation: 5,
      codePosition: 0,
      showCharacter: false
    };
  },
  computed: {
    shuffledCode() {
      return [...this.code]
          .reverse()
          .sort(() => (Math.random() < 0.5 ? -1 : 1))
          .join("");
    }
  },

  methods: {
    async symbolClickHandler(event, location) {
      this.stopAndHideTimer();
      if (location === this.characterLocation) {
        this.$emit("score-up");

        this.cursor.lockPosition = true;
        this.showCharacter = true;

        // get target point
        const targetLeft =
            event.currentTarget.offsetLeft + event.currentTarget.offsetWidth / 2;
        const targetTop =
            event.currentTarget.offsetTop +
            event.currentTarget.offsetHeight / 2 -
            5;

        // calculate how much bounce we want
        const moveDistance = Math.abs(
            Math.sqrt(
                Math.pow(this.cursor.left - targetLeft, 2) +
                Math.pow(this.cursor.left - targetLeft, 2)
            )
        );
        const normalizedDistance = Math.min(moveDistance, 1000) / 1000;
        const bounceAmount = 1 + this.parseEase("expo.out")(normalizedDistance);
        // animate cursor
        await this.animation.to(this.$refs.cursor, {
          duration: 0.25 + 0.25 * normalizedDistance,
          left: targetLeft,
          top: targetTop,
          ease: `back.out(${bounceAmount})`
        });

        setTimeout(() => {
          this.nextCharacter();
        }, 1000);
      }
    },

    getUniqueNewPosition () {
      do {
        this.characterLocation = Math.floor(Math.random() * 15);
      } while (this.previousLocations.includes(this.characterLocation));

      this.addToPreviousLocations(this.characterLocation);
    },
    nextCharacter() {
      this.stopAndHideTimer();
      if (this.codePosition >= this.code.length - 1) {
        console.log('hints done');
        this.$emit("next");
      } else {
        this.startTimer(200);
        console.log('we go to next');
        this.showCharacter = false;
        this.codePosition++;

        this.getUniqueNewPosition();
        this.cursor.lockPosition = false;
      }
    }
  },

  async mounted() {
    this.getUniqueNewPosition();

    this.setTimerStopAfter(300);
    await this.sleep(1500);
    await this.startTimer(200);

    this.$el.addEventListener(
        "mousemove",
        (event) => {
          if (this.cursor.lockPosition === false) {
            this.cursor.left = event.clientX;
            this.cursor.top = event.clientY - 125;
          }
        }
    );
    this.$el.addEventListener("mousein", (event) => {
      this.cursor.show = true;
    });
    this.$el.addEventListener(
        "mouseout",
        (event) => {
          this.cursor.show = false;
        }
    );
  }
};
