//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from "src/shared/GlobalMixins.js";

export default {
  props: {
    value: String
  },
  mixins: [GlobalMixin],
  data() {
    return {
      QWERTY: "QWERTYUIOP|ASDFGHJKL|ZXCVBNM",
      NUMBERS: "789|456|123"
    };
  },
  methods: {
    input(character) {
      this.$emit("input", this.value + character);
    }
  }
};
