//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    character: ""
  }
};
