import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/TheDecoder/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    seeingGlass: 'seeing_glass.webp',
    symbol0: 'symbol_0.webp',
    symbol1: 'symbol_1.webp',
    symbol2: 'symbol_2.webp',
    symbol3: 'symbol_3.webp',
    symbol4: 'symbol_4.webp',
    symbol5: 'symbol_5.webp',
    symbol6: 'symbol_6.webp',
    symbol7: 'symbol_7.webp',
    symbol8: 'symbol_8.webp',
    symbol9: 'symbol_9.webp',
    symbol10: 'symbol_10.webp',
    symbol11: 'symbol_11.webp',
    symbol12: 'symbol_12.webp',
    symbol13: 'symbol_13.webp',
    symbol14: 'symbol_14.webp',
  }
};

export default generateAssets(assetsDir, gameAssets);